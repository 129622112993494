import * as React from 'react'

import moment from 'moment'
import { RawIntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'

import {
  DEFAULT_FULL_LOCALE,
  DEFAULT_LOCALE,
  generateIntl,
  translationMessages,
} from '../../../core/i18n'
import { makeSelectFullLocale, makeSelectLocale } from '../../../stores/i18n'

export const LanguageProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const locale = useSelector(makeSelectLocale())
  const fullLocale = useSelector(makeSelectFullLocale())

  const intl = React.useMemo(
    () =>
      generateIntl({
        locale: fullLocale,
        defaultLocale: fullLocale,
        messages: translationMessages[locale],
        wrapRichTextChunksInFragment: true,
      }),
    [locale, fullLocale]
  )

  React.useEffect(() => {
    if (locale !== DEFAULT_LOCALE || fullLocale !== DEFAULT_FULL_LOCALE)
      moment.locale(fullLocale || DEFAULT_LOCALE)
  }, [locale, fullLocale])

  return (
    <RawIntlProvider value={intl}>
      {React.Children.only(children)}
    </RawIntlProvider>
  )
}
