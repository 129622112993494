import { type Enum } from '@webapp/utils'

export const APItemTypes = {
  BILL_ITEM: 'bill-item',
  CREDIT_CARD_EXPENSE_ITEM: 'credit-card-expense-item',
  CREDIT_CARD_PURCHASE_ORDER_ORDER_ITEM:
    'credit-card-purchase-order-order-item',
  CREDIT_CARD_RECONCILE_ITEM: 'credit-card-reconcile-item',
  EXPENSE_ITEM: 'expense-item',
  PURCHASE_ORDER_DEPOSIT_CREDIT_ITEM: 'purchase-order-deposit-credit-item',
  PURCHASE_ORDER_DEPOSIT_ITEM: 'purchase-order-deposit-item',
  PURCHASE_ORDER_DISCOUNT_ITEM: 'purchase-order-discount-item',
  PURCHASE_ORDER_ORDER_ITEM: 'purchase-order-order-item',
  PURCHASE_ORDER_OTHER_ITEM: 'purchase-order-other-item',
  PURCHASE_ORDER_RECURRING_ORDER_ITEM: 'purchase-order-recurring-order-item',
  PURCHASE_ORDER_SHIPPING_ITEM: 'purchase-order-shipping-item',
  PURCHASE_ORDER_TAX_ITEM: 'purchase-order-tax-item',
  RECEIVE_BY_COST_BLANKET_ORDER_ITEM: 'receive-by-cost-blanket-order-item',
  RECEIVE_BY_QTY_BLANKET_ORDER_ITEM: 'receive-by-qty-blanket-order-item',
} as const

export type APItemType = Enum<typeof APItemTypes>
