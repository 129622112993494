import { useSelector } from 'react-redux'

import { type PermissionEnum } from '@webapp/core/entities/internal/enums/PermissionEnum'
import { makeSelectPermissions } from '@webapp/stores/global'

export const useFilterPermissions = (
  permissions: PermissionEnum[]
): PermissionEnum[] => {
  const allPermissionsArray = useSelector(makeSelectPermissions()) || []
  const allPermissionsSet = new Set(
    allPermissionsArray.map((perm) => perm.codename)
  )

  return permissions.filter((permission) => allPermissionsSet.has(permission))
}
