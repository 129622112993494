import { type PermissionEnum } from '@webapp/core/entities/internal/enums/PermissionEnum'
import { type Permission } from '@webapp/models'

export const hasPermission = (
  permissions: Permission[],
  permission: PermissionEnum
) => {
  return permissions.map((perm) => perm.codename).includes(permission)
}

export const hasNoPermissions = (permissions: Permission[]) => {
  return permissions.length === 0
}
