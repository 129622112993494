/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 */

import moment from 'moment'
import { createIntl, createIntlCache } from 'react-intl'
import type { IntlCache, IntlShape } from 'react-intl'

import enTranslationMessages from './translations/en.json'

export const DEFAULT_LOCALE = 'en'
export const DEFAULT_FULL_LOCALE = 'en-us'

// prettier-ignore
export const appLocales = [
  'en',
  'de'
]

let cache: IntlCache | null
let intl: IntlShape

const generateIntl = (config: Parameters<typeof createIntl>[0]) => {
  if (cache) {
    cache = null
  }
  cache = createIntlCache()
  intl = createIntl(config, cache)
  return intl
}

export { generateIntl, intl }

const formatTranslationMessages = (
  locale: string,
  messages: { [key: string]: string }
) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {}

  const flattenFormattedMessages = (
    formattedMessages: { [key: string]: string },
    key: string
  ) => {
    const formattedMessage: string =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }

  return Object.keys(messages).reduce(flattenFormattedMessages, {})
}

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
}

moment.locale(DEFAULT_FULL_LOCALE)
