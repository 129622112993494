import produce from 'immer'

import { UPDATE_LOCALE } from './constants'
import { type ILanguageProviderState, type LocaleActions } from './types'
import { DEFAULT_FULL_LOCALE, DEFAULT_LOCALE } from '../../core/i18n'

/** @techdebt :tech-debt/reorganize-and-type-application-locales */
function getSeparator(locale: string) {
  const tokens = new Intl.NumberFormat(locale).formatToParts(9999.9)
  const tokensObject = Object.assign(
    {},
    ...tokens.map((token) => ({ [token.type]: token.value }))
  )

  return {
    decimalSeparator: tokensObject.decimal,
    thousandSeparator: tokensObject.group,
  }
}

export const initialState: ILanguageProviderState = {
  locale: DEFAULT_LOCALE,
  fullLocale: DEFAULT_FULL_LOCALE,
  decimalSeparator: getSeparator(DEFAULT_LOCALE).decimalSeparator,
  thousandSeparator: getSeparator(DEFAULT_LOCALE).thousandSeparator,
}

export const reducer = (state = initialState, action: LocaleActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_LOCALE:
        draft.decimalSeparator = getSeparator(
          action.locale.locale
        ).decimalSeparator
        draft.thousandSeparator = getSeparator(
          action.locale.locale
        ).thousandSeparator
        draft.locale = action.locale.locale
        draft.fullLocale = action.locale.fullLocale
        break
    }
  })
