import { useSelector } from 'react-redux'

import { type PermissionEnum } from '@webapp/core/entities/internal/enums/PermissionEnum'
import { makeSelectPermissions } from '@webapp/stores/global'
import { hasPermission } from '@webapp/utils/permissions'

export const useCheckPermission = (permission: PermissionEnum): boolean => {
  const permissions = useSelector(makeSelectPermissions()) || []

  return hasPermission(permissions, permission)
}
