import { useSelector } from 'react-redux'

import { makeSelectPermissions } from '@webapp/stores/global'
import { hasNoPermissions } from '@webapp/utils/permissions'

export const useCheckNoPermission = (): boolean => {
  const permissions = useSelector(makeSelectPermissions()) || []

  return hasNoPermissions(permissions)
}
