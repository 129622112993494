import {
  initialize as initializeLD,
  type LDClient,
  type LDContext,
} from 'launchdarkly-js-client-sdk'

import { type User } from '@webapp/models'
import { Teams } from './enums'
import {
  type FeatureFlagConfig,
  type FeatureFlagKey,
  type FeatureFlags,
} from './types'

export const FEATURE_FLAGS_CONFIG: FeatureFlagConfig = {
  // GRANDFATHERED
  CHQ_34_ADD_AMZNPO_TAX_SHIP: [false, Teams.GRANDFATHERED],
  SY_1803_PUNCHOUT_ON_BEHALF_OF: [false, Teams.GRANDFATHERED],
  FT_290_REPLACE_COST_CALCULATION: [false, Teams.GRANDFATHERED],
  FT_195_ADD_TAXES_TO_ORDERS: [false, Teams.GRANDFATHERED],
  FT_4880_ENABLE_INTERCOM: [false, Teams.GRANDFATHERED],

  // Team AP
  FT_6823_BILL_LIST_FILTER_USER_PERMISSION: [false, Teams.TEAM_AP],
  FT_4287_MIGRATE_UNBILLED_ITEMS_PAGE: [false, Teams.TEAM_AP],

  // Team CLC
  CL_1058_AP_GRANULAR_PERMISSIONS: [false, Teams.TEAM_CLC],
  CL_1324_NEW_NO_PERMISSION_PAGE: [false, Teams.TEAM_CLC],

  // Team Pay
  FT_4280_BILL_PAYMENT_CONSOLIDATION: [false, Teams.TEAM_PAYMENTS],
  FT_5912_DIRECT_DEBIT_PAYMENTS: [false, Teams.TEAM_PAYMENTS],
  FT_7376_REMOVE_BANK_NAME_AS_REQUIRED_FIELD: [false, Teams.TEAM_PAYMENTS],

  // Team Integrations
  INT_1516_MONOLITH_ERROR_HANDLING_FOR_PAYMENT_BILL_OR_SYSTEM_ERRORS_AFTER_PAYMENT_SYNC:
    [false, Teams.TEAM_INTEGRATIONS],
  INT_1757_MARKING_BILLS_AS_PAID: [false, Teams.TEAM_INTEGRATIONS],
  INT_1976_MIGRATE_MANAGE_USERS_AND_PERMISSIONS: [
    false,
    Teams.TEAM_INTEGRATIONS,
  ],

  // Team Rapptr
  CT_700_CREATE_PO_SHIPPING_ADDRESS_USE_REQUESTER_NAME: [
    false,
    Teams.TEAM_RAPPTR,
  ],
  FT_5853_ACTUAL_RECEIVE_DATE_ENTRY: [false, Teams.TEAM_RAPPTR],
  FT_6262_REACT_APPROVAL_ROUTING: [false, Teams.TEAM_RAPPTR],
  FT_6241_PUNCHOUT_HARDENING: [false, Teams.TEAM_RAPPTR],
  FT_6224_MIGRATE_ORDER_DETAILS: [false, Teams.TEAM_RAPPTR],

  // Team Spend Sight
  FT_2274_ENABLE_SPEND_INSIGHTS_DASHBOARD: [false, Teams.TEAM_SPENDSIGHT],
  FT_2278_ENABLE_RETURN_ON_SPEND_CALCULATOR: [false, Teams.TEAM_SPENDSIGHT],
  FT_5817_ENABLE_BUDGETS_REDESIGN: [false, Teams.TEAM_SPENDSIGHT],
  FT_5815_ENABLE_RECEIVED_CATEGORY: [false, Teams.TEAM_SPENDSIGHT],

  // Team Architecture
  ARCH_150_MIGRATE_EXPENSE_STATUS: [false, Teams.TEAM_ARCHITECTURE],
  SEGMENT_USE_UNIQUE_ID: [false, Teams.TEAM_ARCHITECTURE],
  // Team Design System
}

export const FEATURE_FLAG_DEFAULTS = Object.keys(FEATURE_FLAGS_CONFIG).reduce(
  (obj, key) => {
    const [defaultValue] = FEATURE_FLAGS_CONFIG[key as FeatureFlagKey]

    return {
      [key]: defaultValue,
      ...obj,
    }
  },
  {}
) as FeatureFlags

interface FeatureFlagServiceConfig {
  clientId: string
  user: User
  domain: string
}

export class FeatureFlagService {
  private static instance: FeatureFlagService | null = null
  private client: LDClient
  private clientId: string
  private options: LDContext
  initialized: boolean = false

  private constructor(config: FeatureFlagServiceConfig) {
    const { clientId, user, domain } = config

    this.clientId = clientId

    this.options = {
      key: 'aa0ceb',
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      custom: {
        domain,
      },
    }

    this.client = initializeLD(this.clientId, this.options, {
      sendEventsOnlyForVariation: true,
    })
  }

  public static async initialize(
    config: FeatureFlagServiceConfig
  ): Promise<FeatureFlagService> {
    if (!FeatureFlagService.instance) {
      FeatureFlagService.instance = new FeatureFlagService(config)
      await FeatureFlagService.instance.initializeClient()
    }
    return FeatureFlagService.instance
  }

  public static getInstance(): FeatureFlagService {
    if (!FeatureFlagService.instance) {
      throw new Error('FeatureFlagService has not been initialized')
    }
    return FeatureFlagService.instance
  }

  private initializeClient(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.client.on('ready', () => {
        this.initialized = true
        resolve()
      })
      this.client.on('failed', (error) => {
        this.initialized = false
        reject(new Error(`LD Client failed to initialize: ${error}`))
      })
    })
  }

  public getFlag(flagKey: string) {
    if (!this.initialized) {
      throw new Error('getFlags method: FeatureFlagService is not initialized')
    }

    return this.client.variation(flagKey)
  }

  public getAllFlags() {
    if (!this.initialized) {
      throw new Error(
        'getAllFlags method: FeatureFlagService is not initialized'
      )
    }
    return this.client.allFlags()
  }
}
